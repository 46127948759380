<template>
  <div class="ui-soft-page-swiper-pagination" data-testid="elem_vrp_001524">
    <template v-for="(slide, index) in swiper.slides" :key="index">
      <ui-button
        v-if="checkHasSlideByIndex(index)"
        :class="{ 'ui-soft-page-swiper-pagination__dot--active': index === swiper.activeIndex }"
        class="ui-soft-page-swiper-pagination__dot"
        @click="goTo(index)"
      />
    </template>
  </div>
</template>

<script lang="ts" setup>
const swiper = useSwiper()

const emit = defineEmits<{
  change: [swiper: typeof swiper.value]
}>()

function goTo(index: number) {
  swiper.value.activeIndex = index
  if (swiper.value.pagination.bullets[index]) {
    swiper.value.pagination.bullets[index].click()
    emit('change', swiper.value)
  }
}

const checkHasSlideByIndex = computed(() => {
  return (index: number) => {
    return swiper.value.pagination.bullets && swiper.value.pagination.bullets[index]
  }
})
</script>

<style lang="scss">
.ui-soft-page-swiper-pagination {
  margin-top: 48rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0 6rem;

  & &__dot {
    padding: 0;
    width: 8rem;
    height: 8rem;
    border-radius: 50rem;
    background: map-get($grayPalette, "color_9b");

    &--active {
      width: 24rem;
      background: map-get($colorPalette, 'color_0a9');
    }
  }
}
</style>
